import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PublicStudentRoute = ({
	isAuthenticated,
	component: Component,
	student,
	...rest
}) => {
	return (
		<Route
			{...rest}
			component={(props) =>
				isAuthenticated ? (
					<Redirect to='/student/students' />
				) : (
					<Component {...props} />
				)
			}
		/>
	)
}

const mapStateToProps = (state) => ({
	student: state.app.student,
	isAuthenticated: !!state.app.student,
})

export default connect(mapStateToProps)(PublicStudentRoute)
