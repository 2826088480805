import crudHandlers from "./_crud";

export default (state, action) => {
  const {type} = action;
  switch (type) {
    
    case 'GET_GEOFENCES_WIALON': {
		var { response } = action

		return {
			...state,
			is_fetching: false,
			wialon: response.error ? []:response,
		}
	}

    case 'GET_OPTIMIZED_ROUTE': {
		var { response } = action

		return {
			...state,
			is_fetching: false,
			optimized: response,
		}
	}
    

	case 'GET_ROUTE_IMG': {
		var { response } = action

		return {
			...state,
			is_fetching: false,
			route_img: response.img,
		}
	}

    default:

      state = crudHandlers(state, action, 'location')

      return state
  }
}