import crud from './_crud'
import { callApi } from 'utils/api'

const actions = crud(
	'student_notification',
	'la notificación del estudiante',
	'las notificaciones de los estudiantes'
)

const loadStudentNotificationsBindings = (params) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/student_notification/student_notifications`, {
			method: 'GET',
			params: params,
			headers: {
				Authorization: state.app.student.token,
			},
		})

		// dispatch({
		// 	type: `GET_${entity.toUpperCase()}S_WIALON`,
		// 	response: res.body,
		// })

		return res.body
	}
}

export default {
	...actions,
	loadStudentNotificationsBindings,
}
