import { callApi } from 'utils/api'

export const login = ({ username, password }) => {
	return async (dispatch, getState) => {
		
		try {
			const res = await callApi(`/app/login`, {
				method: 'POST',
				body: JSON.stringify({
					username,
					password,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@token', token)

					dispatch({
						type: 'LOGIN',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const loginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.token,
				}),
			})
			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@token', token)
					dispatch({
						type: 'LOGIN',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const logout = () => {
	try {
		localStorage.removeItem('@token')
		localStorage.removeItem('@employee_code')
	} catch (e) {
		// remove error
	}
	return {
		type: 'LOGOUT',
	}
}

export const updatePassword = (item) => {
	return async (dispatch, getState) => {
		try {
			const state = getState()
			const res = await callApi(`/user/change_password`, {
				method: 'POST',
				body: JSON.stringify(item),
				headers: {
					Authorization: state.app?.user?.token,
				},
				successMessage: 'Se actualizó correctamente',
			})

			dispatch({
				type: 'UPDATE_PASSWORD',
				payload: res.body,
			})
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const wialonLogin = (item) => {
	return async (dispatch, getState) => {
		try {
			const state = getState()
			const res = await callApi(`/wialon/login`, {
				method: 'POST',
				body: JSON.stringify(item),
				headers: {
					Authorization: state.app?.user?.token,
				},
			})

			dispatch({
				type: 'LOGIN_WIALON',
				payload: res.body,
			})
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const docentLogin = ({ curp, password }) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/docent`, {
				method: 'POST',
				body: JSON.stringify({
					curp,
					password,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@docent_token', token)

					dispatch({
						type: 'LOGIN_DOCENT',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const docentLoginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/docent/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.docent_token,
				}),
			})
			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@docent_token', token)
					dispatch({
						type: 'LOGIN_DOCENT',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const docentLogout = () => {
	try {
		localStorage.removeItem('@docent_token')
	} catch (e) {
		// remove error
	}
	return {
		type: 'DOCENT_LOGOUT',
	}
}

export const clientLogin = ({ code, password }) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/client`, {
				method: 'POST',
				body: JSON.stringify({
					code,
					password,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@client_token', token)

					dispatch({
						type: 'LOGIN_CLIENT',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const clientLoginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/client/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.client_token,
				}),
			})
			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@client_token', token)
					dispatch({
						type: 'LOGIN_CLIENT',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const clientLogout = () => {
	try {
		localStorage.removeItem('@client_token')
	} catch (e) {
		// remove error
	}
	return {
		type: 'CLIENT_LOGOUT',
	}
}

export const driverLogin = ({ code, password }) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/driver`, {
				method: 'POST',
				body: JSON.stringify({
					code,
					password,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@driver_token', token)

					dispatch({
						type: 'LOGIN_DRIVER',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const passengerLogin = ({ code, password }) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/passenger`, {
				method: 'POST',
				body: JSON.stringify({
					code,
					password,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@passenger_token', token)

					dispatch({
						type: 'LOGIN_PASSENGER',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const studentLogin = ({ code, password }) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/student`, {
				method: 'POST',
				body: JSON.stringify({
					code,
					password,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@student_token', token)

					dispatch({
						type: 'LOGIN_STUDENT',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}
export const studentLoginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/student/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.student_token,
				}),
			})
			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@student_token', token)
					dispatch({
						type: 'LOGIN_STUDENT',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const studentLogout = () => {
	try {
		localStorage.removeItem('@student_token')
	} catch (e) {
		// remove error
	}
	return {
		type: 'STUDENT_LOGOUT',
	}
}

export const passengerLoginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/passenger/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.passenger_token,
				}),
			})
			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@passenger_token', token)
					dispatch({
						type: 'LOGIN_PASSENGER',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}
export const driverLoginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/driver/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.driver_token,
				}),
			})
			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@driver_token', token)
					dispatch({
						type: 'LOGIN_DRIVER',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const driverLogout = () => {
	try {
		localStorage.removeItem('@driver_token')
	} catch (e) {
		// remove error
	}
	return {
		type: 'DRIVER_LOGOUT',
	}
}
export const passengerLogout = () => {
	try {
		localStorage.removeItem('@passenger_token')
	} catch (e) {
		// remove error
	}
	return {
		type: 'PASSENGER_LOGOUT',
	}
}

export const excelLayout = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/${options.entity_name}/generate_layout_excel`, {
			method: 'PUT',
			headers: {
				Authorization: state.app.user.token,
			},
		})
			.then((res) => {
				let buff = Buffer.from(res.body)
				//res.body.blob();
				return buff
			})
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(new Blob([blob]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `${options.entity_name}_layout.xlsx`)

				// Append to html link element page
				document.body.appendChild(link)

				// Start download
				link.click()

				// Clean up and remove the link
				link.parentNode.removeChild(link)
			})
	}
}

export const importFile = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/${options.entity_name}/import_data_from_excel`,
			{
				method: 'PUT',
				json: false,
				headers: {
					Authorization: state.app.user.token,
				},
				body: {
					file: options.files,
				},
			}
		)

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

export const verifyImportData = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/${options.entity_name}/validate_data_from_import`,
			{
				method: 'PUT',
				headers: {
					Authorization: state.app.user.token,
				},
				body: JSON.stringify(options.data),
			}
		)

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}
