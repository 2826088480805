const initialState = {
    concepts: []
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'LOAD_CONCEPTS': 
            return { ...state, concepts: payload }
        default:
            return state
    }
}