import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { Accordion, Popup, Icon, Grid, Menu, Item } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import './Sidebar.scss'
import styles from './../../styles/global.module.scss'
import linkActions from 'store/actions/links.js'

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window
	return {
		width,
		height,
	}
}

const StudentSidebarMenu = (props) => {
	const { expand, setExpand, client, location, mobileOnly, setMobileOnly } =
		props

	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	)
	const [isActive, setIsActive] = useState(false)
	const [isSubActive, setIsSubActive] = useState(false)
	const [links, setLinks] = useState([])

	useEffect(() => {
		componentDidMount()
		function handleResize() {
			if (windowDimensions !== getWindowDimensions())
				setWindowDimensions(getWindowDimensions())
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	const componentDidMount = async () => {
		let list = await props.loadLinksStudent({})
		setLinks(list)
	}

	useEffect(() => {
		if (windowDimensions.width <= 425) {
			setMobileOnly(true)
			if (expand === 2 || expand == null) setExpand(1)
		} else {
			setMobileOnly(false)
		}
	}, [windowDimensions])

	useEffect(() => {
		if (location) {
			!mobileOnly ? setExpand(0) : setExpand(1)
		}
	}, [location])

	return (
		<>
			<div
				className='sidebar'
				style={
					expand == null || expand == 2
						? { width: '250px' }
						: expand == 1
						? { width: '55px' }
						: null
				}
			>
				<Accordion
					key={`accordion-menu-sidebar`}
					fluid
					style={{ paddingLeft: '12px', paddingBottom: '5px' }}
				>
					<Accordion.Title
						key={`title-sidebar`}
						active={isActive}
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							setIsActive(!isActive)
						}}
					>
						<Popup
							trigger={
								<Menu.Item
									className={`${styles.shrinkOnHover} SubMenu__Item`}
									style={{ width: '100%', display: 'inline-block' }}
								>
									<Icon
										name='linkify'
										size='large'
										className='SideBar__Outline'
									/>
									{(expand == null || expand == 2) && (
										<h5 className='menu-row-title'> Enlaces </h5>
									)}
								</Menu.Item>
							}
							positionFixed
							position='right center'
							pinned
							hoverable
							closeOnTriggerClick={true}
							offset={[0, 0]}
							className='SubMenu__Content'
							content={
								<Grid divided='vertically' style={{ width: '15rem' }}>
									<Grid.Row style={{ paddingBottom: 0 }}>
										<Grid.Column>
											<h4>Enlaces</h4>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row style={{ paddingTop: '0', paddingBottom: '0' }}>
										<Grid.Column>
											<Accordion.Content
												key={`content-entidades`}
												style={{ marginTop: '0px', paddingTop: '0px' }}
												active={isActive}
											>
												<Menu
													vertical
													fluid
													text
													style={{ paddingLeft: '30px' }}
												>
													{links.map((link) => {
														return (
															<Menu.Item
																className={styles.growOnHover}
																style={{ margin: '0' }}
																onClick={(event) => {
																	event.preventDefault()
																	window.open(
																		'https://' + link.url,
																		'_blank',
																		'noopener',
																		'noreferrer'
																	)
																}}
															>
																{link.title}
															</Menu.Item>
														)
													})}
												</Menu>
											</Accordion.Content>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							}
						/>
					</Accordion.Title>
				</Accordion>
				<Accordion
					key={`accordion-menu-sidebar`}
					fluid
					style={{ paddingLeft: '12px', paddingBottom: '5px' }}
				>
					<Accordion.Title
						key={`title-sidebar`}
						active={isActive}
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							setIsActive(!isActive)
						}}
					>
						<Popup
							trigger={
								<Menu.Item
									className={`${styles.shrinkOnHover} SubMenu__Item`}
									style={{ width: '100%', display: 'inline-block' }}
								>
									<Icon
										name='address card'
										size='large'
										className='SideBar__Outline'
									/>
									{(expand == null || expand == 2) && (
										<h5 className='menu-row-title'> Notificaciones </h5>
									)}
								</Menu.Item>
							}
							positionFixed
							position='right center'
							pinned
							hoverable
							closeOnTriggerClick={true}
							offset={[0, 0]}
							className='SubMenu__Content'
							content={
								<Grid divided='vertically' style={{ width: '15rem' }}>
									<Grid.Row style={{ paddingBottom: 0 }}>
										<Grid.Column>
											<h4>Notificaciones</h4>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row style={{ paddingTop: '0', paddingBottom: '0' }}>
										<Grid.Column>
											<Accordion.Content
												key={`content-entidades`}
												style={{ marginTop: '0px', paddingTop: '0px' }}
												active={isActive}
											>
												<Menu
													vertical
													fluid
													text
													style={{ paddingLeft: '0px' }}
												>
													<Link
														key={`link-student_notifications`}
														to={`/student/students_notifications`}
														className='sub_menu_row'
													>
														<Menu.Item
															className={styles.growOnHover}
															style={{ margin: '0' }}
														>
															Notificaciones de Estudiantes
														</Menu.Item>
													</Link>
												</Menu>
											</Accordion.Content>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							}
						/>
					</Accordion.Title>
				</Accordion>
			</div>
		</>
	)
}

const mapDispatchToProps = (dispatch) => ({
	loadLinksStudent: (options) =>
		dispatch(linkActions.loadLinksStudent(options)),
})

const mapStateToProps = (state) => ({
	student: state.app.student,
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentSidebarMenu)
