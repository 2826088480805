const initialState = {
    notificationsType: [],
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch(type) {
        case 'LOAD_NOTIFICATIONS_TYPE': {
            return { ...state, notificationsType: payload }
        }
        default: 
            return state;
    }
}