import { createStore, combineReducers, applyMiddleware, compose } from 'redux'

//  Reducers
import appReducer from './reducers/app'
import companyReducer from './reducers/companys'
import locationReducer from './reducers/locations'
import jobTitleReducer from './reducers/job_title'
import placeReducer from './reducers/places'
import employeeReducer from './reducers/employees'
import userReducer from './reducers/users'
import roleReducer from './reducers/roles'
import departmentReducer from './reducers/departments'
import stateReducer from './reducers/states'
import documentReducer from './reducers/document'
import documentItemsReducer from './reducers/document_items'
import sysCompanyReducer from './reducers/sys_companys'
import sysModuleReducer from './reducers/sys_modules'
import categoryReducer from './reducers/categorys'
import formReducer from './reducers/forms'
import formInstanceReducer from './reducers/form_instances'
import cityReducer from './reducers/citys'
import boothReducer from './reducers/booths'
import groupReducer from './reducers/groups'
import tutorReducer from './reducers/tutors'
import studentReducer from './reducers/students'
import studentNotificationReducer from './reducers/students_notifications'
import linkReducer from './reducers/links'
import subjectReducer from './reducers/subjects'
import attendance_subjectReducer from './reducers/attendances_subjects'
import subject_groupReducer from './reducers/subjects_groups'
import careerReducer from './reducers/careers'
import school_periodReducer from './reducers/school_periods'
import shift_Reducer from './reducers/shifts'
import lesson_Reducer from './reducers/lessons'
import general_parameterReducer from './reducers/general_parameters'
import conceptReducer from './reducers/concept'
import notifications_typeReducer from './reducers/notifications_type'
import student_conceptsReducer from './reducers/student_concepts'

//Middleware
import thunk from 'redux-thunk'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
	const store = createStore(
		combineReducers({
			app: appReducer,
			companys: companyReducer,
			locations: locationReducer,
			job_titles: jobTitleReducer,
			places: placeReducer,
			employees: employeeReducer,
			users: userReducer,
			roles: roleReducer,
			departments: departmentReducer,
			states: stateReducer,
			documents: documentReducer,
			document_items: documentItemsReducer,
			sys_companys: sysCompanyReducer,
			sys_modules: sysModuleReducer,
			categorys: categoryReducer,
			citys: cityReducer,
			forms: formReducer,
			form_instances: formInstanceReducer,
			booths: boothReducer,
			groups: groupReducer,
			tutors: tutorReducer,
			students: studentReducer,
			student_notifications: studentNotificationReducer,
			links: linkReducer,
			subjects: subjectReducer,
			attendance_subjects: attendance_subjectReducer,
			subject_groups: subject_groupReducer,
			careers: careerReducer,
			school_periods: school_periodReducer,
			shifts: shift_Reducer,
			lessons: lesson_Reducer,
			general_parameters: general_parameterReducer,
			concept: conceptReducer,
			notifications_type: notifications_typeReducer,
			student_concepts: student_conceptsReducer,
		}),
		composeEnhancers(applyMiddleware(thunk))
	)
	return store
}
