import React, { Suspense, lazy, useEffect, useState } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import PublicRoute from 'components/Routes/PublicRoute'
import PrivateRoute from 'components/Routes/PrivateRoute'
import {
	loginWithToken,
	studentLoginWithToken,
	docentLoginWithToken,
} from 'store/actions/app'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import PublicStudentRoute from 'components/Routes/PublicStudentRoute'
import PublicDocentRoute from 'components/Routes/PublicDocentRoute'
import PrivateStudentRoute from 'components/Routes/PrivateStudentRoute'
import PrivateDocentRoute from 'components/Routes/PrivateDocentRoute'

const HomePage = lazy(() => import('./home/HomePage'))
const LoginRoute = lazy(() => import('./login/index'))
const RolePage = lazy(() => import('./settings/RolePage'))
const RoleIndividualPage = lazy(() => import('./settings/RoleIndividualPage'))

const StudentsPage = lazy(() => import('./caseta/StudentSite/StudentPage'))
const DocentsPage = lazy(() => import('./DocentSite/DocentPage'))

const StudentsNotificationsPage = lazy(() =>
	import('./caseta/StudentSite/StudentNotificationsPage')
)
const ConsoleSuperAdmin = lazy(() => import('./login/ConsoleSuperAdmin'))

const StudentLogin = lazy(() => import('./login/StudentLogin'))

const DocentLogin = lazy(() => import('./login/DocentLogin'))
const DocentGroupPage = lazy(() => import('./DocentSite/DocentGroupPage'))
const AttendanceListPage = lazy(() => import('./DocentSite/AttendanceListPage'))

const Routers = (props) => {
	const [isTryingToLogin, setIsTryingToLogin] = useState(true)

	const tryToLogin = async () => {
		const token = localStorage.getItem('@token')
		const employee_code = localStorage.getItem('@employee_code')
		const student_token = localStorage.getItem('@student_token')
		const docent_token = localStorage.getItem('@docent_token')

		if (token) {
			if (employee_code) {
				await props.loginWithToken({ token, employee_code })
			} else {
				await props.loginWithToken({ token })
			}
		}
		if (student_token) {
			await props.studentLoginWithToken({ student_token })
		}

		if (docent_token) {
			await props.docentLoginWithToken({ docent_token })
		}

		setIsTryingToLogin(false)
	}
	useEffect(() => {
		tryToLogin()
	}, [])

	if (isTryingToLogin) {
		return (
			<Dimmer active inverted className='padding-0'>
				<Loader inverted>Cargando</Loader>
			</Dimmer>
		)
	}

	const modulesToRender = props.user?.modules.filter((mod) => {
		const { permisitions } = mod

		return permisitions?.ACCESS
	})

	return (
		<React.Fragment>
			<BrowserRouter>
				<Suspense
					fallback={
						<Segment className='width-100 height-100 padding-0 border-none margin-0 border-radius-0'>
							<Dimmer active inverted className='padding-0'>
								<Loader inverted>Cargando página</Loader>
							</Dimmer>
						</Segment>
					}
				>
					<Switch>
						<PublicRoute
							path='/login_superadmin'
							component={ConsoleSuperAdmin}
							exact={true}
						/>
						<PublicStudentRoute
							path='/login_student'
							component={StudentLogin}
							exact={true}
						/>
						<PublicDocentRoute
							path='/login_docent'
							component={DocentLogin}
							exact={true}
						/>
						<PublicRoute path='/login' component={LoginRoute} exact={true} />
						<PrivateRoute path='/' component={HomePage} exact={true} />
						<PrivateRoute
							path='/settings/role'
							component={RolePage}
							exact={true}
						/>
						<PrivateRoute
							path='/settings/role/:id'
							component={RoleIndividualPage}
							exact={true}
						/>

						{props.isStudentAuthenticated && (
							<PrivateStudentRoute
								path='/student/students'
								component={StudentsPage}
								exact={true}
							/>
						)}

						{props.isStudentAuthenticated && (
							<PrivateStudentRoute
								path='/student/students_notifications'
								component={StudentsNotificationsPage}
								exact={true}
							/>
						)}

						{props.isDocentAuthenticated && (
							<PrivateDocentRoute
								path='/docent/docents'
								component={DocentsPage}
								exact={true}
							/>
						)}

						{props.isDocentAuthenticated && (
							<PrivateDocentRoute
								path='/docent/attendance_list'
								component={AttendanceListPage}
								exact={true}
							/>
						)}

						{props.isAuthenticated && (
							<>
								{modulesToRender.map((m) => {
									const ComponentPage = lazy(() => import(`${m.folder}`))

									if (ComponentPage)
										return (
											<PrivateRoute
												path={`/${m.url}`}
												component={ComponentPage}
												title={m.name}
												exact={true}
												key={m.url}
											/>
										)
								})}
							</>
						)}
					</Switch>
				</Suspense>
			</BrowserRouter>
		</React.Fragment>
	)
}
const mapDispatchToProps = (dispatch) => ({
	loginWithToken: (parmas) => dispatch(loginWithToken(parmas)),
	studentLoginWithToken: (params) => dispatch(studentLoginWithToken(params)),
	docentLoginWithToken: (params) => dispatch(docentLoginWithToken(params)),
})

const mapStateToProps = (state) => ({
	user: state.app.user,
	docent: state.app.docent,
	isAuthenticated: !!state.app.user,
	isStudentAuthenticated: !!state.app.student,
	isDocentAuthenticated: !!state.app.docent,
})

export default connect(mapStateToProps, mapDispatchToProps)(Routers)
