import crud from './_crud'
import { callApi } from 'utils/api'
import { showErrorMessage, showSuccessMessage } from 'utils/messages'
import _ from 'lodash'

const getStudentsNotifications = (params) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/student/utils/get_students_notifications`, {
			method: 'GET',
			params: params,
			headers: {
				Authorization: state.app.user.token,
			},
		})

		return res.body
	}
}

const createStudent = (item, options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/student/create_student`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				student: item,
			}),
			successMessage: `Se agregó el estudiante correctamente.`,
		})
		showSuccessMessage('El estudiante se registro correctamente')
		return res.body
	}
}

const updateStudent = (item, options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/student/update_student`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				student: item,
			}),
			successMessage: `Se actualizo el estudiante correctamente.`,
		})
		showSuccessMessage('El estudiante se actualizo correctamente')
		return res.body
	}
}

const deleteStudent = (item, options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/student/delete_student`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				student: item,
			}),
			successMessage: `Se elimino el estudiante correctamente.`,
		})
		showSuccessMessage('El elimino se actualizo correctamente')
		return res.body
	}
}

const loadStudentsBindings = (params) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/wialon/students_bindings`, {
			method: 'GET',
			params: params,
			headers: {
				Authorization: state.app.user.token,
			},
		})

		// dispatch({
		// 	type: `GET_${entity.toUpperCase()}S_WIALON`,
		// 	response: res.body,
		// })

		let result = []

		if(res.body && Array.isArray(res.body)){
			res.body.forEach((b)=>{
				const day = new Date(b.t*1000).toLocaleDateString();
				let bind = result.findIndex(r=>r.tag_id == b.tag_id && r.day == day)

				if(bind<0){
					result.push({ tag_id: b.tag_id, t: b.t, day: day, extra_bindings: []})
				}else{
					if(!result[bind].tu){
						result[bind] = { ...result[bind], tu: b.t }
					}else{
						const extra_bind = result[bind].tu
						result[bind] = { 
							...result[bind], 
							extra_bindings: [ ...result[bind].extra_bindings, extra_bind],
							tu: b.t
						}
					}
				}
			})		
		}

		return result
	}
}

const loadStudentBindings = (params) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/wialon/student_bindings`, {
			method: 'GET',
			params: params,
			headers: {
				Authorization: state.app.student.token,
			},
		})

		// dispatch({
		// 	type: `GET_${entity.toUpperCase()}S_WIALON`,
		// 	response: res.body,
		// })

		let result = []

		if(res.body && Array.isArray(res.body)){
			res.body.forEach((b)=>{
				const day = new Date(b.t*1000).toLocaleDateString();
				let bind = result.findIndex(r=>r.tag_id == b.tag_id && r.day == day)

				if(bind<0){
					result.push({ tag_id: b.tag_id, t: b.t, day: day, extra_bindings: []})
				}else{
					if(!result[bind].tu){
						result[bind] = { ...result[bind], tu: b.t }
					}else{
						const extra_bind = result[bind].tu
						result[bind] = { 
							...result[bind], 
							extra_bindings: [ ...result[bind].extra_bindings, extra_bind],
							tu: b.t
						}
					}
				}
			})		
		}

		return result
	}
}

const importStudents = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/student/import_students`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				options,
			}),
			successMessage: `Se agreron los estudiantes correctamente.`,
		})
		showSuccessMessage('Se registraron los estudiantes correctamente')
		return res.body
	}
}


const actions = crud('student', 'el estudiante', 'los estudiantes')

export default {
	...actions,
	deleteStudent,
	updateStudent,
	createStudent,
	loadStudentsBindings,
	loadStudentBindings,
	importStudents,
	getStudentsNotifications,
}
