import crud from './_crud'
import { callApi } from 'utils/api'

const loadLinksStudent = (params) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/link/get_links`, {
			method: 'GET',
			params: params,
			headers: {
				Authorization: state.app.student.token,
			},
		})

		// dispatch({
		// 	type: `GET_${entity.toUpperCase()}S_WIALON`,
		// 	response: res.body,
		// })

		return res.body
	}
}

const actions = crud('link', 'el enlace', 'los enlaces')

export default {
	...actions,
	loadLinksStudent,
}
