import crudHandlers from "./_crud";

export default (city, action) => {
    const { type } = action;
    switch (type) {

        default:

            city = crudHandlers(city, action, 'city')

            return city
    }
}