import crud from "./_crud";
import { callApi } from "utils/api";
 
const entity = 'document_item';
const entityMsg = 'Documento';

const loadExpirationReport = () => {
    return async (dispatch, getState) => {
      const state = getState();
  
      const res = await callApi(`/${entity}/utils/get_expiration_report`, {
        method: 'GET',
        headers: {
          Authorization: state.app.user.token
        }
      })
  
      dispatch({
        type: `LOAD_REPORT_${entity.toUpperCase()}`,
        response: res.body
      })
  
      return res.body
    }
}

let customActions = {}
customActions = {...customActions,loadExpirationReport}
const actions = {...crud(entity, 'el documento', 'los documentos'), ...customActions};

export default actions