const initialState = {
    students: [],
    subjects: [],
    studentConcepts: []
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch(type) {
        case 'LOAD_STUDENTS_FOR_CONCEPT_REPORT':
            return { ...state, students: payload }
        case 'LOAD_SUBJECTS_FOR_CONCEPT_REPORT':
            return { ...state, subjects: payload }
        case 'LOAD_STUDENT_CONCEPTS':
            return { ...state, studentConcepts: payload }

        default: 
            return state;
    }
}